<template>
  <div class="container">
    <el-container>
      <el-aside class="aside" style="background-color: #545c64">
        <el-menu v-if="data.foldMenus" active-text-color="#ffd04b" background-color="#5b6874" class="el-menu-vertical-demo" default-active="2" text-color="#fff">
          <el-sub-menu style="background-color: #545c64;" v-for="(menu, index) in data.menus" :key="menu.label" :index="index + 1 + ''" v-show="!menu.hidden">
            <template #title>
              <span>{{ menu.label }}</span>
            </template>
            <el-menu-item v-for="(page, index2) in menu.pages" :index="index + '-' + index2" :key="page.path" @click="router.push('/' + page.path)">{{ page.label }}</el-menu-item>
          </el-sub-menu>
        </el-menu>
        <el-menu v-else active-text-color="#ffd04b" background-color="#545c64" class="el-menu-vertical-demo" default-active="2" text-color="#fff">
          <div v-for="(menu, index) in data.menus" :key="menu.label" v-show="!menu.hidden">
            <el-menu-item v-for="(page, index2) in menu.pages" :index="index + '-' + index2" :key="page.path" @click="router.push('/' + page.path)">{{ page.label }}</el-menu-item>
          </div>
        </el-menu>
      </el-aside>
      <el-container>
        <!-- <el-aside class="aside">
          <el-collapse accordion>
            <el-collapse-item v-for="menu in data.menus" :key="menu.label" :title="menu.label" :name="menu.label" v-show="!menu.hidden">
              <div v-for="page in menu.pages" :key="page.label">
                <el-button text @click="router.push('/' + page.path)">{{ page.label }}</el-button>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-aside> -->
        <el-header class="header" style="display: flex; justify-content: space-between;">
          <div style="display: flex; justify-content:flex-start;">
            <div style="margin-right: 20px;">
              <el-link type="primary" :underline="false" v-show="data.foldMenus" @click="data.foldMenus = false"><el-icon><Menu /></el-icon></el-link>
              <el-link type="primary" :underline="false" v-show="!data.foldMenus" @click="data.foldMenus = true"><el-icon><Grid /></el-icon></el-link>
            </div>
            <div style="margin-right: 20px;">
              <el-text>启和物流 数字化系统</el-text>
            </div>
          </div>
          <div style="display: flex; justify-content:flex-end;">
            <div style="margin-right: 20px;">
              <el-link @click="router.push('/my')">{{ user.nick }}</el-link>
            </div>
            <div style="margin-right: 20px;">
              <el-link @click="logout()">安全退出</el-link>
            </div>
          </div>
        </el-header>
        <el-main class="main">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../apis/api'
import menusConf from '../router/menusConf'
import cache from '../utils/cache'

const router = useRouter()

const user = cache.getObject(cache.keys.loginUser) || {}

const data = reactive({
  menus: [],
  menuQuantity: 0,
  foldMenus: true,
})

api.get('/backend/user/getMyInfo').then(res => {
  if (res.admin) {
    data.menus = menusConf.filter(false)
  } else {
    data.menus = menusConf.filter(false, res.role.menuList)
  }
  let menuQuantity = 0
  data.menus.forEach(menu => {
    menuQuantity += menu.pages.length
  })
  data.menuQuantity = menuQuantity
  if (menuQuantity < 10) {
    data.foldMenus = false
  }
})

const logout = () => {
  api.post('/backend/user/logout').then(() => {
    cache.setObject(cache.keys.loginUser, null)
    ElMessage.success('已安全退出')
    router.push('/login')
  })
}
</script>

<style lang="less">
.container {
  .aside {
    width: 250px;
    height: 100vh;
  }

  .header {
    height: 49px;
    line-height: 49px;
    overflow: hidden;
    border-bottom: 1px solid #eee;
  }

  .main {
    height: calc(100vh - 55px);
    width: calc(100vw - 270px);
  }

  /deep/ .el-menu {
    background-color: #5b6874;
  }

  /deep/ .el-submenu .el-menu {
    background-color: #283847;
  }
}
</style>